/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
//import useMediaQuery from '@mui/material/useMediaQuery';
import ServiceLevelAgreement from './ServiceLevelAgreement';
//import { ReactComponent as Wave } from '../static/wave.svg';
//import wave from '../static/wave.svg';

function Rates({ ratesRef }) {
  //const isMobile = useMediaQuery('(max-width:600px)');
  //const isMd = useMediaQuery('(min-width:601px) and (max-width:1200px)');

  const title = (
    <Typography
      variant='h1'
      color={'white'}
      fontFamily='FORGE'
      fontWeight='500'
      align='center'
      sx={{
        fontSize: { xs: 70, md: 90 },
      }}
    >
      Rates
    </Typography>
  );

  const content = (
    <>
      <Card
        variant='outlined'
        sx={{
          width: 425,
          padding: 2,
          borderRadius: 4,
          backgroundColor: '#191919',
          borderColor: 'white',
        }}
      >
        <CardContent>
          <Typography
            fontFamily='FORGE Filled'
            align='center'
            variant='h4'
            component='div'
            color='white'
          >
            Managed Devices
          </Typography>
          <Typography
            gutterBottom
            fontFamily='FORGE Filled'
            align='center'
            variant='h6'
            component='div'
            color='white'
          >
            (Under SLA Contract)
          </Typography>
          <Typography
            color='white'
            fontFamily='Forge Filled'
            fontWeight={'200'}
            fontSize={18}
            component='div'
          >
            <ul style={{ marginBottom: 0 }}>
              <li>$100/hour - Desktop</li>
              <li>$140/hour - Infrastructure</li>
            </ul>
          </Typography>
        </CardContent>
      </Card>

      <Card
        variant='outlined'
        sx={{
          width: 425,
          padding: 2,
          borderRadius: 4,
          backgroundColor: '#191919',
          borderColor: 'white',
          justifyContent: 'space-evenly',
        }}
      >
        <CardContent>
          <Typography
            fontFamily='FORGE Filled'
            align='center'
            variant='h4'
            component='div'
            color='white'
          >
            Unmanaged Devices
          </Typography>
          <Typography
            gutterBottom
            fontFamily='FORGE Filled'
            align='center'
            variant='h6'
            component='div'
            color='white'
          >
            (Best Effort)
          </Typography>
          <Typography
            color='white'
            fontFamily='Forge Filled'
            fontWeight={'200'}
            fontSize={18}
            component='div'
          >
            <ul style={{ marginBottom: 0 }}>
              <li>$140/hour - Desktop</li>
              <li>$180/hour - Infrastructure</li>
            </ul>
          </Typography>
        </CardContent>
      </Card>
    </>
  );

  return (
    <Grid
      container
      item
      rowGap={4}
      mb={4}
      justifyContent='center'
      backgroundColor='#262626'
    >
      <Grid xs={12} mt={5} ref={ratesRef}>
        {title}
      </Grid>
      <Grid
        container
        item
        xs={10}
        justifyContent='center'
        columnGap={4}
        rowGap={4}
        marginBottom={10}
      >
        <Typography
          color={'white'}
          variant={'p'}
          sx={{
            textAlign: { xs: 'center' },
            fontSize: { xs: 20, md: 20 },
          }}
          fontFamily="FORGE Filled"
          fontWeight="500"
        >
          In order to serve you best, FORGE is reassessing and restructuring our rates.
        </Typography>
        <Typography
          color={'white'}
          variant={'p'}
          sx={{
            textAlign: { xs: 'center' },
            fontSize: { xs: 20, md: 20 },
          }}
          fontFamily="FORGE Filled"
          fontWeight="500"
        >
          We are proud to announce that the updated rate information will be publicly available on our refreshed website.
        </Typography>
      </Grid>
      
    </Grid>
  );
}

export default Rates;
