/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { Typography } from '@mui/material';
import { ReactComponent as VanIsle } from '../static/vancouver-island.svg';
//import useMediaQuery from '@mui/material/useMediaQuery';

function About({ aboutRef }) {
  //const isMobile = useMediaQuery('(max-width:600px)');

  const title = (
    <Typography
      variant="h1"
      color={'white'}
      sx={{
        textAlign: { xs: 'center' },
        fontSize: { xs: 70, md: 90 },
      }}
      fontFamily="FORGE"
      fontWeight="500"
    >
      About
    </Typography>
  );

  const content = (
    <Typography
      color={'white'}
      fontSize={'1rem'}
      fontFamily="FORGE Filled"
      fontWeight={'100'}
      mt={2}
    >
      Based in Victoria, British Columbia, FORGE TECHNICAL SOLUTIONS
      is a digital and technical services provider that takes pride in
      serving our clients. With a strong belief in transparency and
      honesty, we strive to build lasting relationships based on
      trust, integrity, and high-quality service delivery. We
      understand the unique challenges that the constantly evolving
      technological landscape presents and provide reliable,
      dependable solutions tailored to each client's specific needs.
    </Typography>
  );

  return (
    <Grid container item mb={10} justifyContent="center">
      <Grid xs={12} md={10} ref={aboutRef}>
        {title}
      </Grid>

      <Grid
        container
        item
        justifyContent={'center'}
        rowGap={7}
        columnGap={3}
        xs={10}
        lg={8}
      >
        <Grid xs={12} md={8} lg={7}>
          {content}
        </Grid>
        <Grid xs={8} sm={5} md={3} xl={2}>
          <VanIsle width={'100%'} height={'100%'} />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default About;
