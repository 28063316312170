/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from 'react';
//import { useRecoilState } from 'recoil';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { Fade, Icon } from '@mui/material';
//import { scrollState, windowState } from '../state/atoms';
import { ReactComponent as Hamburger } from '../static/menu.svg';
import './Nav.css';

const pages = ['Services', 'Rates', 'About', 'Contact'];

function ResponsiveAppBar({
  servicesRef,
  ratesRef,
  aboutRef,
  contactRef,
}) {
  const [anchorElNav, setAnchorElNav] = useState(null);
  //const [windowSize, setWindowSize] = useRecoilState(windowState);
  //const [scrollPosition, setScrollPosition] = useRecoilState(scrollState);

  const refMap = {
    Services: servicesRef,
    Rates: ratesRef,
    About: aboutRef,
    Contact: contactRef,
  };

  /*const forgeLogo = useMemo(() => {
    if (scrollPosition > windowSize.height/2) {
      return (
        <Fade in={true} timeout={500}>
          <div>
            <span style={{ color: '#ff0000' }}>F</span>
            <span style={{ color: '#ff3300' }}>O</span>
            <span style={{ color: '#ff6600' }}>R</span>
            <span style={{ color: '#ff9900' }}>G</span>
            <span style={{ color: '#ffcc00' }}>E</span>
          </div></Fade>
        
      );
    } else {
      return (
        <Fade in={true} timeout={500}>
          <div style={{visibility: 'hidden'}}>
            <span style={{ color: '#464646' }}>F</span>
            <span style={{ color: '#6a6a6a' }}>O</span>
            <span style={{ color: '#909090' }}>R</span>
            <span style={{ color: '#b5b5b5' }}>G</span>
            <span style={{ color: '#dadada' }}>E</span>
          </div></Fade>
        
      );
    }
  }, [ scrollPosition, windowSize.height]);*/

  const forgeLogo = (
    <div>
      <span style={{ color: '#ff0000' }}>F</span>
      <span style={{ color: '#ff3300' }}>O</span>
      <span style={{ color: '#ff6600' }}>R</span>
      <span style={{ color: '#ff9900' }}>G</span>
      <span style={{ color: '#ffcc00' }}>E</span>
    </div>
  );

  /*const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  };

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { width: innerWidth, height: innerHeight };
  }*/

  const ForgeHamburgerIcon = () => (
    <Icon sx={{ width: '35px', height: '35px' }}>
      <Hamburger />
    </Icon>
  );

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleNavClick = (page) => {
    const y =
      refMap[page].current.getBoundingClientRect().top +
      window.scrollY -
      75;
    window.scrollTo({ top: y, behavior: 'smooth' });
    handleCloseNavMenu();
  };

  return (
    <AppBar
      sx={{ backgroundColor: { xs: 'rgba(0,0,0,0)', lg: '#191919' } }}
      elevation={0}
      position='fixed'
    >
      {/** Desktop Nav */}
      <Container maxWidth='xl'>
        <Toolbar>
          <Typography
            variant='h3'
            noWrap
            component='a'
            href='/'
            sx={{
              mr: 3,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'FORGE',
              fontWeight: 600,
              textDecoration: 'none',
            }}
          >
            {forgeLogo}
          </Typography>

          {/** Mobile Nav */}
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'flex', md: 'none' },
              justifyContent: { xs: 'flex-end', md: 'initial' },
            }}
          >
            <IconButton
              size='large'
              aria-label='hamburger-nav'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={handleOpenNavMenu}
            >
              <ForgeHamburgerIcon />
            </IconButton>

            <Menu
              id='menu-appbar'
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem
                  key={page}
                  onClick={(e) => handleNavClick(page)}
                >
                  <Typography textAlign='center'>{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          {/** Desktop nav menu (pages) */}
          <Box
            sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}
          >
            {pages.map((page) => (
              <Button
                key={page}
                value={page}
                onClick={(e) => handleNavClick(page)}
                sx={{
                  my: 2,
                  color: 'white',
                  display: 'block',
                  fontFamily: 'FORGE Filled',
                  fontSize: '18px',
                }}
              >
                {page}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
