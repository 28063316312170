/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import { useInView } from 'framer-motion';
import Grid from '@mui/material/Unstable_Grid2';
import { Box, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import ServiceCard from './ServiceCard';
import serviceData from '../utils/serviceData';

function Services({ servicesRef }) {
  const isInView = useInView(servicesRef, {
    once: true,
    amount: 'some',
  });
  const timeout = 500;
  const isMobile = useMediaQuery('(max-width:600px)');

  const title = (
    <Typography
      color={'white'}
      variant={'h1'}
      sx={{
        textAlign: { xs: 'center' },
        fontSize: { xs: 70, md: 90 },
      }}
      fontFamily='FORGE'
      fontWeight='500'
    >
      Services
    </Typography>
  );

  return (
    <Grid
      container
      item
      rowGap={4}
      justifyContent='center'
      mt={10}
      mb={10}
    >
      <Grid xs={12} ref={servicesRef}>
        {title}
      </Grid>

      <Grid
        container
        item
        justifyContent={'center'}
        rowGap={4}
        columnGap={4}
        xs={12}
        xl={10}
      >
        {serviceData.map((service, index) => (
          <ServiceCard
            key={index}
            cardObject={service}
            isInView={isInView}
            timeout={timeout + index * 300}
          />
        ))}
      </Grid>
    </Grid>
  );
}

export default Services;
