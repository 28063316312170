import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { ReactComponent as Logo } from '../static/forge-technical-solutions.svg';
import { Typography } from '@mui/material';
import chamber from '../static/5_chamber.png';
import sooke from '../static/sooke_chamber_white.png';
import Footer from './Footer';

function Chamber() {
  return (
    <>
    <Grid
      xs={12}
      container
      alignItems="center"
      justifyContent="center"
      paddingY={15}
    >
      <Grid xs={10} lg={7}>
        <a href='/'>
        <Logo /></a>
      </Grid>
      <Grid xs={10} sm={10} textAlign="center" marginTop={10}>
        <Typography
          color={'white'}
          variant={'p'}
          sx={{
            textAlign: { xs: 'center' },
            fontSize: { xs: 40, md: 50 },
          }}
          fontFamily="FORGE Filled"
          fontWeight="500"
        >
          Is proud to present the
        </Typography>
      </Grid>
      <Grid xs={10} sm={8} md={7} textAlign="center" marginTop={4}>
          <img src={chamber} alt='5 Chamber Mixer graphic' height={'100%'} width={'100%'} />
      </Grid >
      <Grid xs={10} sm={8} md={7} textAlign="center" marginTop={4}>
        <Typography
          color={'white'}
          variant={'p'}
          sx={{
            textAlign: { xs: 'center' },
            fontSize: { xs: 40, md: 50 },
          }}
          fontFamily="FORGE Filled"
          fontWeight="500"
        >
          Hosted by the
        </Typography>
      </Grid>
      <Grid xs={10} sm={8} md={7} textAlign="center" marginTop={4} >
          <img src={sooke} alt='5 Chamber Mixer graphic' height={'70%'} width={'70%'} />
      </Grid >
      <Grid xs={10} sm={10} textAlign="center" marginTop={10}>
        <Typography
          color={'white'}
          variant={'p'}
          sx={{
            textAlign: { xs: 'center' },
            fontSize: { xs: 30 },
          }}
          fontFamily="FORGE Filled"
          fontWeight="500"
        >
          A huge thank you to Deb and the rest of the chamber teams for putting together such an amazing event!
        </Typography>
      </Grid>
      <Grid xs={10} sm={10} textAlign="center" marginTop={4}>
        <Typography
          color={'white'}
          variant={'p'}
          sx={{
            textAlign: { xs: 'center' },
            fontSize: { xs: 30 },
          }}
          fontFamily="FORGE Filled"
          fontWeight="500"
        >
          We hope you enjoy experiencing sooke culture and making memorable connections.
        </Typography>
      </Grid>
    </Grid>
    <Footer />
    </>
  );
}

export default Chamber;
