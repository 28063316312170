import React from 'react';
import Home from './Home';
import { RecoilRoot } from 'recoil';


function App() {
  return (
    <RecoilRoot>
      <div className="App">
        <Home />
      </div>
      
    </RecoilRoot>
  );
}

export default App;
