/* eslint-disable no-unused-vars */
import React, { useRef } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import Services from './components/Services';
import Rates from './components/Rates';
import About from './components/About';
import Nav from './components/Nav';
import Landing from './components/Landing';
import Footer from './components/Footer';
import Contact from './components/Contact';
import ComingSoon from './components/ComingSoon';
//import { ReactComponent as Wave } from './static/wave.svg';
//import wave from './static/wave.svg';



function Home() {
  const servicesRef = useRef();
  const ratesRef = useRef();
  const aboutRef = useRef();
  const contactRef = useRef();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Nav
        servicesRef={servicesRef}
        ratesRef={ratesRef}
        aboutRef={aboutRef}
        contactRef={contactRef}
      />

      <Grid container justifyContent={'center'} alignItems={'center'}>
        <Grid
          xs={12}
          sx={{ height: '100vh' }}
          container
          alignItems='center'
          justifyContent='center'
        >
          <Landing />
        </Grid>
        <ComingSoon />
        <Services servicesRef={servicesRef} />

        <Rates ratesRef={ratesRef} />

        <About aboutRef={aboutRef} />

        <Contact contactRef={contactRef} />
      </Grid>
      <Footer />
    </Box>
  );
}

export default Home;
