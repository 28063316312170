import React from 'react';
import { ReactComponent as Arrow } from '../static/service_icons/arrow.svg';
import { ReactComponent as Cloud } from '../static/service_icons/cloud.svg';
import { ReactComponent as Computer } from '../static/service_icons/computer.svg';
import { ReactComponent as Cubes } from '../static/service_icons/cubes.svg';
import { ReactComponent as Headset } from '../static/service_icons/headset.svg';
import { ReactComponent as Microsoft } from '../static/service_icons/microsoft.svg';
import { ReactComponent as Route } from '../static/service_icons/route.svg';
import { ReactComponent as Vault } from '../static/service_icons/vault.svg';

const serviceData = [
  {
    title: 'In-House IT Support',
    icon: <Headset fill={'white'} height={135} />,
    content: ['Fully or co-managed IT support', 'Onsite and remote'],
  },
  {
    title: 'Device Management',
    icon: <Computer fill={'white'} height={135} width={180} />,
    content: [
      'Security, system, and software updates',
      'Disaster recovery planning and testing',
      'System monitoring and alerting',
      'Compliance and usage reporting',
      'Asset tracking and documentation',
    ],
  },
  {
    title: 'Backup Strategy',
    icon: <Vault fill={'white'} height={135} />,
    content: [
      'Design, implementation, and maintenance',
      'Workstation, server, and M365 backup solutions',
      'Disaster recovery planning and testing',
    ],
  },
  {
    title: 'Infrastructure Modernization and Virtualization',
    icon: <Cubes fill={'white'} height={135} />,
    content: [
      'Workstation and peripheral upgrades',
      'Server and application virtualization',
      'File storage system revamping',
    ],
  },
  {
    title: 'Microsoft 365 and Microsoft Office',
    icon: <Microsoft fill={'white'} height={135} />,
    content: [
      'Teams, Outlook, Word, Excel, and the rest of the Office Suite',
      'M365 implementation and account management',
    ],
  },
  {
    title: 'Cloud',
    icon: <Cloud fill={'white'} width={185} />,
    content: [
      'Email with Exchange Online',
      'File storage with SharePoint and OneDrive',
    ],
  },
  {
    title: 'Business Process Revamping',
    icon: <Arrow fill={'white'} height={135} width={180} />,
    content: [
      'IT driven processes and optimizations',
      'Companywide standardization utilizing best practices',
    ],
  },
  {
    title: 'Future Proofing and Planning',
    icon: <Route fill={'white'} height={135} />,
    content: [
      'Infrastructure designed to last, be upgradable, and efficiently maintained',
      'Timeline view of suggested and required upgrades, replacements, and initiatives',
    ],
  },
];

export default serviceData;
