/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { Typography } from '@mui/material';

function Contact({ contactRef }) {
  //const isMobile = useMediaQuery('(max-width:600px)');

  const title = (
    <Typography
      variant='h1'
      color={'white'}
      align='center'
      sx={{
        fontSize: { xs: 70, md: 90 },
      }}
      fontFamily='FORGE'
      fontWeight='500'
    >
      Contact
    </Typography>
  );

  return (
    <Grid
      xs={12}
      container
      item
      rowGap={4}
      justifyContent='center'
      backgroundColor='#262626'
      pb={12}
    >
      <Grid xs={12} mt={5} ref={contactRef}>
        {title}
      </Grid>

      <Grid
        container
        item
        justifyContent={'center'}
        rowGap={4}
        xs={12}
      >
        <Grid xs={12} lg={5}>
          <Typography
            color={'white'}
            fontFamily='FORGE Filled'
            fontWeight={300}
            sx={{ fontSize: { xs: 25, md: 35 } }}
            mt={2}
            align={'center'}
          >
            <a
              href='mailto:support@forge-ts.ca'
              style={{ textDecoration: 'none', color: 'white' }}
            >
              support@<span style={{ color: '#ff0000' }}>F</span>
              <span style={{ color: '#ff3300' }}>O</span>
              <span style={{ color: '#ff6600' }}>R</span>
              <span style={{ color: '#ff9900' }}>G</span>
              <span style={{ color: '#ffcc00' }}>E</span>-ts.ca
            </a>
          </Typography>
        </Grid>
        <Grid xs={12} lg={5}>
          <Typography
            color={'white'}
            sx={{ fontSize: { xs: 25, md: 35 } }}
            align={'center'}
            fontFamily='FORGE Filled'
            fontWeight={300}
            mt={2}
          >
            <a
              href='tel:2508181670'
              style={{ textDecoration: 'none', color: 'white' }}
            >
              (250) 818-1670
            </a>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Contact;
