/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Collapse, Zoom } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

function ServiceCard({ cardObject, timeout, isInView }) {
  const [open, setOpen] = useState(false);

  return (
    <Zoom in={isInView} timeout={timeout}>
      <Grid width={300}>
        <Card
          onClick={() => setOpen(!open)}
          onMouseLeave={() => setOpen(false)}
          sx={{
            '&:hover': { transform: 'scale3d(1.05, 1.05, 1)' },
            '&:active': { transform: 'scale3d(1.0, 1.0, 1)' },
            backgroundColor: '#292929',
            borderRadius: 2,
            paddingLeft: 1,
            paddingRight: 1,
            cursor: 'pointer',
          }}
          elevation={5}
        >
          <CardMedia
            sx={{
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              flexDirection: 'column',
              height: 300,
            }}
          >
            {cardObject.icon}
            <Typography
              color={'white'}
              fontFamily='FORGE Filled'
              gutterBottom
              variant='h5'
              component='div'
              align='center'
            >
              {cardObject.title}
            </Typography>
          </CardMedia>

          <Collapse in={open} timeout={700}>
            <CardContent sx={{ padding: 0 }}>
              <Typography
                color={'white'}
                fontFamily='FORGE Filled'
                fontWeight={'100'}
                fontSize={18}
                component='div'
              >
                <ul style={{ marginTop: 0 }}>
                  {cardObject.content.map((item) => (
                    <li key={item}>{item}</li>
                  ))}
                </ul>
              </Typography>
            </CardContent>
          </Collapse>
        </Card>
      </Grid>
    </Zoom>
  );
}

export default ServiceCard;
