import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { ReactComponent as Logo } from '../static/forge-technical-solutions.svg';

function Landing() {
  return (
    <Grid xs={10} sm={8} md={7}>
      <Logo />
    </Grid>
  );
}

export default Landing;
