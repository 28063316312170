import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
//import Button from '@mui/material/Button';


function Footer() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position='static'
        elevation={0}
        sx={{ backgroundColor: '#202020' }}
      >
        <Toolbar>
          <Typography
            fontFamily='Forge'
            flexGrow={1}
            sx={{ fontSize: { xs: 25, md: 40 }, textDecoration: 'none' }}
            href='/'
            component='a'
          >
            <div>
              <span style={{ color: '#464646' }}>F</span>
              <span style={{ color: '#6a6a6a' }}>O</span>
              <span style={{ color: '#909090' }}>R</span>
              <span style={{ color: '#b5b5b5' }}>G</span>
              <span style={{ color: '#dadada' }}>E</span>
            </div>
          </Typography>
          <Typography variant='body2' color='gray'>
            <span>&copy;</span> 2024 FORGE TECHNICAL SOLUTIONS INC.
          </Typography>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default Footer;
