import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { Typography } from '@mui/material';

function ComingSoon() {
  return (
    <Grid
      container
      item
      rowGap={4}
      justifyContent="center"
      mt={10}
      mb={10}
    >
      <Grid xs={12}>
        <Typography
          color={'white'}
          variant={'h1'}
          sx={{
            textAlign: { xs: 'center' },
            fontSize: { xs: 50, md: 70 },
          }}
          fontFamily="Inter"
          fontWeight="800"
        >
          Great things are coming
        </Typography>
      </Grid>
      <Grid
        container
        item
        justifyContent={'center'}
        rowGap={4}
        columnGap={4}
        xs={10}
        xl={10}
      >
        <Typography
          color={'white'}
          variant={'p'}
          sx={{
            textAlign: { xs: 'center' },
            fontSize: { xs: 20, md: 25 },
          }}
          fontFamily="Inter"
          fontWeight="500"
        >
        
          FORGE is working tirelessly on creating our new website that
          will better capture our services and offerings.
        </Typography>
        <Typography
          color={'white'}
          variant={'p'}
          sx={{
            textAlign: { xs: 'center' },
            fontSize: { xs: 20, md: 25 },
          }}
          fontFamily="Inter"
          fontWeight="500"
        >
          For more
          immediate information on how we can help your business reach
          its full potential, or to be notified when the new website
          is available, reach out to Shaun at {' '}
          <a href='mailto:shaun.cuglietta@forge-ts.ca' style={{textDecoration: 'none', color: 'grey'}}>shaun.cuglietta@forge-ts.ca</a>.
        </Typography>
      </Grid>
    </Grid>
  );
}

export default ComingSoon;
